// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tree-guild-companion-plants-tsx": () => import("./../../../src/pages/tree-guild-companion-plants.tsx" /* webpackChunkName: "component---src-pages-tree-guild-companion-plants-tsx" */),
  "component---src-pages-tree-guild-result-tsx": () => import("./../../../src/pages/tree-guild-result.tsx" /* webpackChunkName: "component---src-pages-tree-guild-result-tsx" */),
  "component---src-pages-tree-guild-tsx": () => import("./../../../src/pages/tree-guild.tsx" /* webpackChunkName: "component---src-pages-tree-guild-tsx" */),
  "component---src-pages-tree-guilds-tsx": () => import("./../../../src/pages/tree-guilds.tsx" /* webpackChunkName: "component---src-pages-tree-guilds-tsx" */)
}

